import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/rezepte"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/tipps-bei-durchfall/" title>
                          Tipps bei Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first last leaf">
                            <a href="/tipps-fuer-den-winter/" title>
                              Tipps für den Winter
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded active-trail">
                        <a
                          href="/richtig-essen/"
                          title
                          className="active-trail"
                        >
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien und -unverträglichkeiten
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>
                          <li className="last leaf active-trail">
                            <a
                              href="/rezepte/"
                              title
                              className="active-trail active"
                            >
                              Gesunde Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">Gesunde Rezepte</div>
                        </div>
                      </div>
                    </h1>
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <h3>Beeren-Limetten-Shake</h3>
                          <p>
                            <strong>&nbsp;</strong>
                            <strong>
                              <img
                                alt
                                src="/sites/default/files/Blaubeershake.JPG"
                                style={{
                                  width: "220px",
                                  height: "330px",
                                  float: "right",
                                  margin: "12px 100px"
                                }}
                              />
                            </strong>
                          </p>
                          <p>
                            <strong>Zutaten für 4 Portionen:</strong>
                          </p>
                          <ul
                            style={{
                              "margin-top": "0pt"
                            }}
                          >
                            <li>100 g Heidelbeeren</li>
                            <li>50 g schwarze Johannisbeeren</li>
                            <li>400 ml Sojamilch</li>
                            <li>1 Prise Zimt</li>
                            <li>1 St unbehandelte Limette (Schale, Saft)</li>
                            <li>2 EL Honig</li>
                            <li>
                              etwas frische Zitronenmelisse
                              <br />
                              &nbsp;
                            </li>
                          </ul>
                          <p>
                            <strong>Zeitaufwand:</strong>
                            <br />
                            Vorbereitungszeit - 15 min
                            <br />
                            Koch-/Kühlzeit - 0 min
                            <br />
                            Zubereitungszeit - 15 min
                            <br />
                            &nbsp;
                          </p>
                          <p>
                            <strong>Zubereitung</strong>:<br />
                            Beeren unter klarem Wasser abspülen, verlesen und
                            auf einem Tuch abtropfen lassen. Kalte Sojamilch,
                            Limettensaft, Honig und Zimt mit den Früchten
                            mischen. Mit dem Mixer oder Pürierstab pürieren und
                            Shake in 2 Gläser umfüllen. Melisse abspülen und
                            trocken schütteln. Anschließend Blätter von den
                            Stielen zupfen und den Shake mit Blättern und
                            Limettenschale dekorieren.
                          </p>
                          <p>
                            <a
                              className="georgia arrow_01"
                              href="/sites/default/files/Imodium_rezept_blaubeershake.pdf"
                              target="_blank"
                            >
                              Download Rezept
                            </a>
                          </p>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                          <h3>Spargel-Carpaccio mit Putenbrust</h3>
                          <h3>&nbsp;</h3>
                          <p>
                            <strong>Zutaten für 4 Portionen</strong>:
                          </p>
                          <ul>
                            <li>300 g Putenbrust Aufschnitt</li>
                            <li>150 g Grüner Spargel</li>
                            <li>150 g Weißer Spargel</li>
                            <li>50 g Cocktailtomaten</li>
                            <li>30 g Rucolasalat, gezupft</li>
                            <li>20 g Süsser Senf</li>
                            <li>1 TL rote Pfefferkörner</li>
                            <li>50 ml Olivenöl</li>
                            <li>1 Zweig Kerbel</li>
                            <li>Salz &amp; Pfeffer aus der Mühle</li>
                            <li>
                              Saft von einer kleinen Zitrone
                              <br />
                              &nbsp;
                            </li>
                          </ul>
                          <p>
                            <strong>Zeitaufwand:</strong>
                            <br />
                            Vorbereitungszeit - 40 min
                            <br />
                            Koch-/Kühlzeit - 0 min
                            <br />
                            Zubereitungszeit - 40 min
                          </p>
                          <p>
                            <strong>Zubereitung</strong>:<br />
                            Spargel schälen und holzige Enden abschneiden.
                            Spargelstangen in gesalzenem Wasser bissfest kochen.
                            Im Anschluss kalt abspülen und für 30
                            Minuten&nbsp;in das Tiefkühlfach legen. Leicht
                            gefrorene Spargel in dünnen Streifen (wie bei einem
                            Carpaccio) schneiden und mit Salz und Pfeffer
                            würzen. Auch den Putenbrust-Aufschnitt in dünne
                            Streifen schneiden und zu dem Spargel geben. Zuletzt
                            die Tomaten waschen und vierteln und ebenfalls dazu
                            geben. Mit dem Zitronensaft und Olivenöl marinieren
                            und einigen Pfefferkörnern und Kerbel dekorieren.
                          </p>
                          <p>
                            Nährwertangaben: Pro Portion: 18 g E, 14 g F, 3 g
                            KH, 218 kcal
                          </p>
                          <h3>&nbsp;</h3>
                          <h3>Saiblingfilet mit Gemüsegarnitur</h3>
                          <p>
                            <strong>Zutaten für 4 Portionen</strong>:
                          </p>
                          <ul>
                            <li>800 g Saiblingfilet</li>
                            <li>2 Tomaten</li>
                            <li>1 kleine Zucchini</li>
                            <li>200 g Champignons</li>
                            <li>1/2 kleiner Staudensellerie</li>
                            <li>Meersalz</li>
                            <li>Pfeffer aus der Mühle</li>
                            <li>1 EL Walnussöl</li>
                            <li>Petersilie</li>
                          </ul>
                          <p>
                            <strong>Zeitaufwand:</strong>
                            <br />
                            Vorbereitungszeit - 30 min
                            <br />
                            Koch-/Kühlzeit - 0 min
                            <br />
                            Zubereitungszeit - 30 min
                            <br />
                            &nbsp;
                          </p>
                          <p>
                            <strong>Zubereitung</strong>:<br />
                            Beginnen Sie damit das Gemüse zu waschen. Schneiden
                            Sie Zucchini und Champignons in ca. 0,5cm dicke
                            Scheiben, den Staudensellerie in 1-2cm lange Stücke
                            und achteln Sie die Tomaten. Anschließend die
                            Petersilie waschen und grob hacken. Mischen Sie
                            Gemüse und Petersilie in einer Schüssel und würzen
                            Sie es mit grobem Pfeffer aus der Mühle und
                            Meersalz. Bereiten Sie 4 Stücke Alufolie von 30x50cm
                            vor und bestreichen Sie diese mit Wallnussöl. Legen
                            Sie ein Saiblingfilet auf jedes Stück und würzen Sie
                            den Fisch. Geben Sie auf die Filets je ein Viertel
                            der Gemüsestücke und wickeln Sie alles fest in die
                            Alufolie ein. Die Päckchen nun für ca 15-20 Minuten
                            bei moderater Hitze auf den Grill geben.
                          </p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded active-trail">
                    <a href="/richtig-essen/" title className="active-trail">
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf active-trail">
                        <a
                          href="/rezepte/"
                          title
                          className="active-trail active"
                        >
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                   <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
